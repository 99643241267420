import React, { useEffect, useState } from 'react'
import ModalWithVideo from '../modal/ModalWithVideo';
import PropTypes from 'prop-types';


function VideoModalSection(props) {
    const [openModalVideo, setOpenModalVideo] = useState(false);
    const {isFirstLogin, email} = props; 

    useEffect(() => {
      const isFirstLoginStorage = localStorage.getItem('isFirstLogin');
      if(isFirstLogin && (!isFirstLoginStorage || isFirstLoginStorage !== email)) {
        localStorage.setItem('isFirstLogin', email); 
        setOpenModalVideo(true);
      }
    }, [isFirstLogin]);
  return (
    <>
       <div className='open_video' onClick={() => setOpenModalVideo(true)} onKeyDown={() => {}} role="none">
        <div className="arrow-right" />
      </div>
      {openModalVideo && <ModalWithVideo setOpen={setOpenModalVideo}/>}
    </>
  )
}

VideoModalSection.propTypes = {
  isFirstLogin: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default VideoModalSection;