import Intercom, {show} from '@intercom/messenger-js-sdk';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function AIChat({user_id, name, email, created_at}) {
  const {pathname} = useLocation(); 
  Intercom({
    app_id: process.env.REACT_APP_AI_CHAT_APP_ID,
    user_id, 
    name, 
    email, 
    created_at, 
  });

  useEffect(() => {
    const isOpenedChat = sessionStorage.getItem('isShowAiChat');  
    if((!isOpenedChat || isOpenedChat === 'false') && (pathname === '/dashboard' || pathname === '/dashboard/')) {
      show(); 
      sessionStorage.setItem('isShowAiChat', 'true'); 
    }
  }, [pathname]); 

  return <div>Ai chat</div>;
}

AIChat.propTypes = {
  user_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
};