import React from "react";
import { Search, Notification, Calculator, User, Switcher } from "@carbon/icons-react";
import PropTypes from 'prop-types';
import {
  Button,
  Header,
  HeaderName,
  HeaderContainer,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SkipToContent,
  HeaderMenuButton,
  OverflowMenu,
  OverflowMenuItem,
  Content,
  Grid,
  Column,
  Theme,
  GlobalTheme
} from '@carbon/react';
import useAuth from '../../hooks/useAuth';
// import { useTranslation } from 'react-i18next';

const Fade16 = () => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-hidden="true"
  >
    <path d="M8.24 25.14L7 26.67a14 14 0 0 0 4.18 2.44l.68-1.88a12 12 0 0 1-3.62-2.09zm-4.05-7.07l-2 .35A13.89 13.89 0 0 0 3.86 23l1.73-1a11.9 11.9 0 0 1-1.4-3.93zm7.63-13.31l-.68-1.88A14 14 0 0 0 7 5.33l1.24 1.53a12 12 0 0 1 3.58-2.1zM5.59 10L3.86 9a13.89 13.89 0 0 0-1.64 4.54l2 .35A11.9 11.9 0 0 1 5.59 10zM16 2v2a12 12 0 0 1 0 24v2a14 14 0 0 0 0-28z" />
  </svg>
);

const DashboardNavbar = (props) => {
  const { logout, configSiteData: {tm} } = useAuth();
  // const { t } = useTranslation();

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="IBM Platform Name">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName
            href="/dashboard/"
            prefix=""
          >
            <img style={{height: "34px"}} className="img-fluid" src={`${process.env.PUBLIC_URL}/logo5.png`} alt="logo"/>
            {tm['Header title 2']}
          </HeaderName>
          <HeaderNavigation>
            <HeaderMenuItem href="/dashboard/">Dashboard</HeaderMenuItem>
            <HeaderMenuItem href="/billing-and-usage/">Billing and usage</HeaderMenuItem>
            <HeaderMenuItem href="https://distancematrix.ai/contact-us" target="_blank">Support Page</HeaderMenuItem>
            <HeaderMenuItem href="https://distancematrix.ai/choose-your-perfect-api" target="_blank">API for me</HeaderMenuItem>
            {/* <HeaderMenuItem href="/streaming-api/">Streaming API</HeaderMenuItem> */}
            {/* <HeaderMenu aria-label="Link 4" menuLinkName="Link 4">
            <HeaderMenuItem href="#">Sub-link 1</HeaderMenuItem>
            <HeaderMenuItem href="#">Sub-link 2</HeaderMenuItem>
            <HeaderMenuItem href="#">Sub-link 3</HeaderMenuItem>
          </HeaderMenu> */}
          </HeaderNavigation>
          <HeaderGlobalBar>
            {/* <Button kind="secondary" style={{ color: '#525252', backgroundColor: '#000000' }}>
              Details of invoice
            </Button> */}
            <HeaderGlobalAction>
              <OverflowMenu
                flipped
                focusTrap={false}
                // ariaLabel="overflow-menu"
                // renderIcon={<UserAvatar size={20} />}
                renderIcon={User}
                size="md"
              >
                <OverflowMenuItem
                  itemText="My Account"
                  href="/account/"
                  style={{ textDecoration: 'none' }}
                />
                <OverflowMenuItem
                  itemText="Logout"
                  hasDivider
                  isDelete
                  onClick={logout}
                />
              </OverflowMenu>
            </HeaderGlobalAction>
          </HeaderGlobalBar>
          <Theme theme="white">
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}>
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem href="/dashboard/">Dashboard</HeaderMenuItem>
                  <HeaderMenuItem href="/billing-and-usage/">Billing and usage</HeaderMenuItem>
                  <HeaderMenuItem href="https://distancematrix.ai/contact-us" target="_blank">Support page</HeaderMenuItem>
                  <HeaderMenuItem href="https://distancematrix.ai/choose-your-perfect-api" target="_blank">API for me</HeaderMenuItem>
 
                  {/* <HeaderMenu aria-label="Link 4" menuLinkName="Link 4">
                <HeaderMenuItem href="#">Sub-link 1</HeaderMenuItem>
                <HeaderMenuItem href="#">Sub-link 2</HeaderMenuItem>
                <HeaderMenuItem href="#">Sub-link 3</HeaderMenuItem>
              </HeaderMenu> */}
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>
          </Theme>
        </Header>
      )}
    />
  );
}

// <Theme theme="g100"></Theme>
// DashboardNavbar.propTypes = {
//   outlet: PropTypes.node
// };

export default DashboardNavbar;
