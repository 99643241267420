import React from 'react'
import PropTypes from 'prop-types';
import { Modal, ModalFooter, ModalHeader, Tile, ModalBody } from '@carbon/react';
import { CheckmarkFilled, Help, InformationFilled, CircleSolid } from "@carbon/icons-react";

function ModalWithVideo({setOpen}) {
    const status = true; 

  return (
    <div className='wrapper_modal'>
        <Modal 
          open={status} 
          size="md" 
          passiveModal 
          style={{}}  
          onRequestClose={() => setOpen(false)}>

           <iframe
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${"6t8GCOMr6V8"}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />

      </Modal>
    </div>
  )
}

ModalWithVideo.propTypes = {
    setOpen: PropTypes.func.isRequired    
};

export default ModalWithVideo; 